import React from "react";
import { Helmet } from "react-helmet";
import Page from "../components/page";

import {
  contactPage,
  pageTitle,
  content,
  boxDecor,
  boxDecorTwo,
  contact,
  contactTitle,
  contactTitleMid,
  contactContent,
  icon,
  info,
} from "../style/layout/_contact.module.scss";
import { faEnvelope, faMapPin, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Teko Merah</title>
        <meta name="author" content="readmerisen" />
        <meta
          name="description"
          content="Do not hesitate to contact us. We are more than happy to help."
        />
        <meta name="twitter:title" content="Teko Merah Contact"></meta>
      </Helmet>
      <Page>
          <div className={contactPage}>
            <div className={pageTitle}>CONTACT US</div>
            <div className={content}>
              <div className={boxDecor}></div>
              <div className={boxDecorTwo}></div>
              <div className={contactTitle}>INQUIRY</div>
              <p>
                We love to hear from you. If you have inquiry about anything,
                the quickest way to reach us is by sending us email at{" "}
                <a href="mailto:hello@tekomerah.com">
                  <span>hello@tekomerah.com</span>
                </a>
              </p>
              <div className={contactTitleMid}>WORK WITH US</div>
              <p>
                We are always looking for talented creative people to join us in
                our journey. If you have background in <em>architecture</em>,{" "}
                <em>interior</em> or <em>product design</em>, please send us
                your cover letter and portfolio. Even though, currently we do
                not have any open position, but we will get in touch as soon as
                we can fit you in!
              </p>
            </div>
            <div className={contact} id="contact-us">
              <div className={contactTitle}>OTHER WAY TO REACH US:</div>
              <div className={contactContent}>
                <address>
                  <div className={icon}>
                    <FontAwesomeIcon icon={faMapPin} />
                  </div>
                  <div className={info}>
                    <span>
                      Jl. Jatiluhur Raya Blok DB5
                      <br />
                      Bekasi 17145
                    </span>
                  </div>
                  <div className={icon}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className={info}>
                    <a href="mailto:hello@tekomerah.com">
                      <span>hello@tekomerah.com</span>
                    </a>
                  </div>
                  <div className={icon}>
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <div className={info}>
                    <a href="tel:+622189457883">
                      <span>+62 21 89457883</span>
                    </a>
                  </div>
                  <div className={icon}>
                    <FontAwesomeIcon icon={faInstagram} />
                  </div>
                  <div className={info}>
                    <a href="https://instagram.com/tekomerah">
                      <span>@tekomerah</span>
                    </a>
                  </div>
                </address>
              </div>
            </div>
          </div>
        </Page>
    </>
  );
};

export default Contact;
