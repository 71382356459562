// extracted by mini-css-extract-plugin
export var pageWrapper = "_contact-module--page-wrapper--3z3IO";
export var pageDesktopWrapper = "_contact-module--page-desktop-wrapper--RN17H";
export var pageTitle = "_contact-module--page-title--1Im1j";
export var dskPage = "_contact-module--dsk-page--Q8UpS";
export var dskPageWrapper = "_contact-module--dsk-page-wrapper--J042X";
export var dskPageTitle = "_contact-module--dsk-page-title--3aQcr";
export var contactPage = "_contact-module--contact-page--1Ya_I";
export var content = "_contact-module--content--3EdHW";
export var boxDecor = "_contact-module--box-decor--3J-z_";
export var boxDecorTwo = "_contact-module--box-decor-two--1a-v4";
export var contact = "_contact-module--contact--2KZLh";
export var contactTitle = "_contact-module--contact-title--Y3zGG";
export var contactTitleMid = "_contact-module--contact-title-mid--XEd_l";
export var contactContent = "_contact-module--contact-content--2Ygx9";
export var icon = "_contact-module--icon--1woEz";
export var info = "_contact-module--info--J0Lwg";